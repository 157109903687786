.header-color{
  background: #000046;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1CB5E0, #000046);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1CB5E0, #000046); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.navLinks Link {
  color: #56CCF2;
}

.landing-grid{
  background: #56CCF2;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2F80ED, #56CCF2);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2F80ED, #56CCF2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.avatar-img{
  height: 250px;
  padding-top: 5em;
}

.banner-text {
  background-color: black;
  opacity: .8;
  width: 75%;
  margin: auto;
  border-radius: 10px;
}

.banner-text h1 {
  font-size: 66px;
  font-weight: bold;
  color: white;
}

.banner-text hr {
  border-top: 5px dotted white;
  width: 50%;
  margin: auto;
}

.banner-text p {
  color: white;
  font-size: 20px;
  padding: 1em;
}

.social-links {
  /*display: flex;*/
  justify-content: space-between;
  width: 50%;
  margin: auto;
}
.social-links i {
  color: white;
  font-size: 6em;
}

.cards {
  display: flex
}

.contact-body {
  margin: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: top;
  padding-top: 2em;
}

.contact-grid {
  text-align: center;
  justify-content: center;
  width: 85%;
  height: 550px;
  background: white;
  color: black;
  margin-bottom: 20px;
}

.contact-grid h2 {
  font-family: 'Roboto', sans-serif;
}

.contact-grid p {
  font-family: 'Oxygen', sans-serif;
  font-weight: bold;
}

.contact-grid hr {
  border-top: 5px dotted black;
  width: 50%;
  margin: auto;
}

.contact-list i{
  font-size: 66px;
  padding-right: 4rem;
  padding-left: 3rem;
}

/*Resume Page*/
.resume-left-col {
  background-color: black;
  opacity: .9;
  width: 75%;
  margin: auto;
  border-radius: 10px;
}

.resume-left-col h2 {
  color: whitesmoke;
}

.resume-left-col h4 {
  color: whitesmoke;
}

.resume-left-col h5 {
  color: whitesmoke;
}

.resume-left-col p {
  color: whitesmoke;
}

.resume-right-col {
  background: #27221f;
  color:white;
  opacity: .99;
  margin: auto;
  border-radius: 10px;
}

.about-me-div {
  padding-left: 2rem;
  padding-right: 2rem;
  background: #27221f;
  color:white;
  opacity: .99;
  width: 95%;
  margin: auto;
  border-radius: 10px;
}

.about-me-div p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.drawer {
  background: black;
  opacity: .90;
}